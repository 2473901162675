import { Language } from 'codegen/types'

export function replacePathname(url: URL, pathname: string) {
  const currentUrl = new URL(url)
  currentUrl.pathname = pathname

  return currentUrl
}

export function stripQueryParams(url: URL, queryParams: string[] | string) {
  const next = new URL(url)

  if (Array.isArray(queryParams)) {
    queryParams.forEach(param => {
      next.searchParams.delete(param)
    })
  } else {
    next.searchParams.delete(queryParams)
  }

  return next
}

export function stripDomain(url: URL) {
  return url.pathname + url.search + url.hash
}

export function replaceUrl(url: URL) {
  window.history.replaceState(null, '', url.toString())
}

export function addOrReplaceLanguageInUrl(url: string, lang: Language): string {
  const urlObj = new URL(url)
  const segments = urlObj.pathname.split('/').filter(Boolean)

  if (segments.length > 0 && Object.values(Language).includes(segments[0] as Language)) {
    segments[0] = lang
  } else {
    segments.unshift(lang)
  }

  urlObj.pathname = segments.join('/')

  if (!urlObj.pathname.startsWith('/')) {
    urlObj.pathname = `/${urlObj.pathname}/`
  }

  if (!urlObj.pathname.endsWith('/')) {
    urlObj.pathname = `${urlObj.pathname}/`
  }

  if (urlObj.pathname.startsWith('/en/')) {
    urlObj.pathname = urlObj.pathname.replace('/en/', '/')
  }

  const result = urlObj.toString()

  return result
}

export function getLanguageSegment(url: string): Language {
  try {
    const urlObj = new URL(url)
    const segments = urlObj.pathname.split('/').filter(Boolean)

    if (segments.length > 0 && Object.values(Language).includes(segments[0] as Language)) {
      return segments[0] as Language
    }
  } catch (error) {}

  return Language.En
}
