import { TOKEN_LOCAL_STORAGE_KEY } from 'codegen/config/fetcher'
import { UserQuery, LogInMutation, RegisterMutation } from 'codegen/generated/user'

// these are the 3 auth queries that, should, return the same data
type UserQueryUser = Truthy<UserQuery['me']>
type LogInMutationUser = Truthy<LogInMutation['login']>
type RegisterMutationUser = Truthy<RegisterMutation['register']>

export type UserAuthResponse =
  | Omit<UserQueryUser, '__typename'>
  | Omit<LogInMutationUser, '__typename'>
  | Omit<RegisterMutationUser, '__typename'>

import { getCookie, sleep } from './dom'

const deviceIdCookieName = 'cfDeviceId'
const deviceIdUndefined = '__notSet__'

const CF_URL = process.env.NEXT_PUBLIC_CF_URL || process.env.VITE_CF_URL

export function getDeviceId() {
  if (typeof document === 'undefined') {
    return deviceIdUndefined
  }

  return getCookie(deviceIdCookieName) || deviceIdUndefined
}

export function isOnCFDomain() {
  return Boolean(location.hostname.match(/\.creativefabrica\.com|creativefabrica\.com/))
}

function saveTokenToLocalStorage(token: string) {
  try {
    localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, token)
  } catch {}
}

/**
 * This function works out what kind of authentication to use for the token.
 * If you are on a .creativefabrica.com or creativefabrica.com domain, it will
 * save the token to a cookie to enable cookie sharing.
 *
 * If that fails, we fallback to using a token.
 *
 * Since cookie sharing only works on the above domains, we have to use localStorage
 * to save the token on other domains.
 */

type AuthOrigin = 'login' | 'registration'
export function saveToken(token: string, origin: AuthOrigin) {
  if (isOnCFDomain()) {
    // if saving the cookie fails, we can save to local storage as a fallback.
    return jwtAuth(token, origin).catch(() => {
      saveTokenToLocalStorage(token)
    })
  }

  saveTokenToLocalStorage(token)

  return Promise.resolve()
}

export async function jwtAuth(token: string, origin: AuthOrigin, attempt = 1) {
  const headers = new Headers()

  headers.append('accept', 'application/json, multipart/mixed')
  headers.append('content-type', 'application/json')

  const res = await fetch(`${CF_URL}/cfsecure/jwtauth`, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      token,
      origin,
    }),
  })

  if (!res.ok) {
    if (attempt > 3) {
      throw new Error('Failed to authenticate with JWT')
    }

    await sleep(2000)
    return jwtAuth(token, origin, attempt + 1)
  }
}

export async function clearToken() {
  try {
    localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY)
  } catch {}
}
