import { useDarkMode } from '@studio/context/dark-mode/dark-mode.context'
import { Moon01Icon, SunIcon } from 'primitives/icons/editor'

export function DarkModeSwitch() {
  const { preference, togglePreference } = useDarkMode()

  return (
    <button className="p-2 outline-none" onClick={togglePreference}>
      {preference === 'light' ? (
        <Moon01Icon className="h-auto w-[24px]" />
      ) : (
        <SunIcon className="h-auto w-[24px]" />
      )}
      <div className="sr-only">Toggle theme</div>
    </button>
  )
}
