import * as React from 'react'
import * as nanny from 'react-nanny'
import { useSetPaymentsDialogVisibility } from '@studio/components/app-dialogs'
import { useUserSubscriptionsGraphicsQuery } from 'codegen/generated/user'
import 'payments-dialog/styles.css'
import { Button } from 'primitives/button'
import { Stars01Icon } from 'primitives/icons'
import { tw } from 'utils/classnames'
import { FullScreenControls } from '@studio/components/full-screen-controls'
import { AppSwitchBar } from '@studio/components/top-app-bar/components/app-switch-bar'
import { DarkModeSwitch } from '@studio/components/top-app-bar/components/dark-mode-switch'
import { HomeButton } from '@studio/components/top-app-bar/components/home-button'

function TopAppBarRight({ children }: { children?: React.ReactNode }) {
  return <>{children}</>
}

function TopAppBarLeft({ children }: React.PropsWithChildren) {
  return children
}

function TopAppBarCenter({ children }: { children?: React.ReactNode }) {
  return <>{children}</>
}

type Variant = 'studio' | 'vectorizer' | 'transfer' | 'flow'

interface TopAppBarRootProps {
  variant?: Variant
}
function TopAppBarRoot(props: React.ComponentProps<'header'> & TopAppBarRootProps) {
  const right = nanny.getChildrenByTypeDeep(props.children, [TopAppBarRight])
  const left = nanny.getChildrenByTypeDeep(props.children, [TopAppBarLeft])
  const center = nanny.getChildrenByTypeDeep(props.children, [TopAppBarCenter])
  const userSubscriptionsGraphicsQuery = useUserSubscriptionsGraphicsQuery(undefined, {
    keepPreviousData: true,
    select: data => Boolean(data.me?.user?.subscriptions?.graphics),
  })
  const isSubscribed = userSubscriptionsGraphicsQuery.data || false
  const isShowGoProForFreeButton = userSubscriptionsGraphicsQuery.isFetched && !isSubscribed
  const openPaymentsModal = useSetPaymentsDialogVisibility()

  return (
    <header
      className={tw(
        'flex h-[56px] items-center justify-between gap-[7px] border-b border-b-gray-800 bg-gray-900 py-[7px] pl-[7px] pr-[12px] sm:pr-[20px]',
        props.className,
      )}
    >
      <div className="flex h-[56px] items-center gap-4">
        <div className="flex items-center gap-1">
          <HomeButton />
          <div className={tw('inline', props?.variant === 'flow' && 'hidden sm:flex')}>
            <AppSwitchBar />
          </div>
        </div>
        <div className="flex flex-row gap-2">{left}</div>
      </div>
      <React.Suspense fallback={null}>{center}</React.Suspense>
      <div className="ml-2 flex items-center gap-3 sm:ml-3">
        {isShowGoProForFreeButton ? (
          <Button type="button" className="hidden min-[1497px]:inline" onClick={openPaymentsModal}>
            <div className="flex w-full">
              <Stars01Icon />
              <span className="pl-2">
                <span className="hidden whitespace-nowrap min-[1580px]:inline">
                  Go pro for free
                </span>
                <span className="inline whitespace-nowrap min-[1580px]:hidden">Go pro</span>
              </span>
            </div>
          </Button>
        ) : null}
        {right}
        <DarkModeSwitch />
        <FullScreenControls />
      </div>
    </header>
  )
}

export const TopAppBar = Object.assign(TopAppBarRoot, {
  Right: TopAppBarRight,
  Left: TopAppBarLeft,
  Center: TopAppBarCenter,
})
