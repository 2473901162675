import * as React from 'react'
import { IconButton } from 'primitives/buttons/icon-button'
import { ExpandIcon } from 'primitives/icons'

const hasFullScreenSupport =
  typeof window !== 'undefined' &&
  typeof document?.documentElement?.requestFullscreen === 'function'

export function FullScreenControls() {
  const [isFullScreen, setIsFullScreen] = React.useState(false)

  function onFullScreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch(err => {
        alert(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`)
      })
    } else {
      onExit()
    }
  }

  function onExit() {
    document.exitFullscreen()
  }

  function onClick() {
    if (isFullScreen) {
      onExit()
    } else {
      onFullScreen()
    }

    setIsFullScreen(!isFullScreen)
  }

  if (!hasFullScreenSupport) {
    return null
  }

  return (
    <IconButton
      variant="transparent"
      onClick={onClick}
      aria-label='Toggle "Fullscreen"'
      className="hidden sm:block"
    >
      <ExpandIcon />
      <div className="sr-only">Toggle fullscreen</div>
    </IconButton>
  )
}
