import { PreloadedLink } from '@studio/components/preloaded-link'
import { Tooltip } from 'primitives/tooltip'
import { ROUTES } from '@studio/routes'
import { Button } from 'primitives/button'
import { CheckboxItem } from 'primitives/checkbox-item'
import {
  BoardsIcon,
  FlowIcon,
  StudioIcon,
  TransferIcon,
  VectorizerIcon,
  BackgroundRemoverIcon,
} from 'primitives/icons'
import { Separator } from 'primitives/separator'
import * as React from 'react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import { tw } from 'utils/classnames'
import FlowThumbnail from '@studio/images/flow-thumbnail-image.png'

type AppSwitchBarItem = {
  title: string
  image: JSX.Element
  icon: JSX.Element
  description: string
  id: string
  topIcon: JSX.Element
}

export function AppSwitchBar() {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const menus = React.useMemo(
    () =>
      [
        {
          title: 'Creative Fabrica Studio',
          active: true,
          image: (
            <img
              src="/images/tool-studio.png"
              width="369"
              height="208"
              className="h-auto w-full bg-gray-800"
              alt="Creative Fabrica Studio"
            />
          ),
          icon: (
            <div className="rounded-2 text-service-white-white flex h-[48px] min-w-[48px] items-center justify-center bg-violet-500">
              <StudioIcon className="h-[24px] min-w-[24px]" />
            </div>
          ),
          description: 'Design your way, with the entire content library and a powerful web editor',
          id: 'Studio',
          topIcon: pathname.includes(ROUTES.STUDIO.NEW_PROJECT) ? (
            <div
              className={tw(
                'rounded-2 text-service-white-white flex items-center justify-center border-0 bg-violet-500 p-[5px] focus:outline-none',
                'hover:text-service-white-white focus:text-service-white-white h-[42px] w-[42px] hover:bg-violet-500 focus:bg-violet-500',
              )}
            >
              <StudioIcon className="h-[24px] min-w-[24px]" />
            </div>
          ) : (
            <PreloadedLink
              // do not preload here otherwise it can create a new project in the background
              preload={false}
              aria-label="Switch to Studio"
              className={tw(
                'bg-gray-1000-100 rounded-2 text-gray-400-400 flex cursor-pointer items-center justify-center border-0 p-[5px] focus:outline-none',
                'hover:text-service-white-white focus:text-service-white-white h-[42px] w-[42px] hover:bg-violet-500 focus:bg-violet-500',
              )}
              to={ROUTES.STUDIO.NEW_PROJECT}
            >
              <StudioIcon className="h-[24px] min-w-[24px]" />
            </PreloadedLink>
          ),
        },
        {
          title: 'Creative Fabrica Flow',
          active: true,
          image: (
            <img
              src={FlowThumbnail}
              width="369"
              height="208"
              className="h-auto w-full bg-gray-800"
              alt="Creative Fabrica Flow"
            />
          ),
          icon: (
            <div className="rounded-2 text-service-white-white flex h-[48px] min-w-[48px] items-center justify-center bg-[#39C0C4]">
              <FlowIcon className="h-[18px] min-w-[24px]" />
            </div>
          ),
          description: 'Create anything you want. Iteratively. For free.',
          id: 'Flow',
          topIcon: (
            <PreloadedLink
              aria-label="Switch to Flow"
              className={tw(
                'bg-gray-1000-100 rounded-2 text-gray-400-400 flex cursor-pointer items-center justify-center border-0 p-[5px] focus:outline-none',
                'hover:text-service-white-white focus:text-service-white-white h-[42px] w-[42px] hover:bg-[#39C0C4] focus:bg-[#39C0C4]',
                pathname.includes(ROUTES.FLOW.ROOT) && 'text-service-white-white bg-[#39C0C4]',
              )}
              to={ROUTES.FLOW.ROOT}
            >
              <FlowIcon className="h-[18px] min-w-[24px]" />
            </PreloadedLink>
          ),
        },
        {
          title: 'CF Spark Boards',
          active: false,
          image: (
            <img
              src="/images/boards-thumbnail.png"
              width="369"
              height="208"
              className="h-auto w-full bg-gray-800"
              alt="Creative Fabrica Spark Boards"
            />
          ),
          icon: (
            <div className="rounded-2 text-service-white-white flex h-[48px] min-w-[48px] items-center justify-center bg-[#F57F20]">
              <BoardsIcon className="h-[20px] min-w-[25px]" />
            </div>
          ),
          description: 'Get access to hundreds of material assets from the new Material Library',
          id: 'Boards',
          topIcon: (
            <PreloadedLink
              aria-label="Switch to Boards"
              className={tw(
                'bg-gray-1000-100 rounded-2 text-gray-400-400 flex cursor-pointer items-center justify-center border-0 p-[5px] focus:outline-none',
                'hover:text-service-white-white focus:text-service-white-white h-[42px] w-[42px] hover:bg-[#F57F20] focus:bg-[#F57F20]',
                pathname === ROUTES.BOARDS.ROOT && 'text-service-white-white bg-[#F57F20]',
              )}
              to={ROUTES.BOARDS.ROOT}
            >
              <BoardsIcon className="h-[20px] min-w-[25px]" />
            </PreloadedLink>
          ),
        },
        {
          title: 'Creative Fabrica Vectorizer',
          active: true,
          image: (
            <img
              src="/images/tool-vectorizer.png"
              width="369"
              height="208"
              className="h-auto w-full bg-gray-800"
              alt="Creative Fabrica Vectorizer"
            />
          ),
          icon: (
            <div className="rounded-2 text-service-white-white flex h-[48px] min-w-[48px] items-center justify-center bg-[#9ACA3C]">
              <VectorizerIcon className="h-[21px] min-w-[18px]" />
            </div>
          ),
          description:
            'Create Scalable Vector Graphics (SVG) from any raster-based image (eg. PNG/JPG)',
          id: 'Vectorizer',
          topIcon: (
            <PreloadedLink
              aria-label="Switch to Vectorizer"
              className={tw(
                'rounded-2 text-gray-400-400 flex cursor-pointer items-center justify-center border-0 p-[5px] focus:outline-none',
                'bg-gray-1000-100 hover:text-service-white-white focus:text-service-white-white h-[42px] w-[42px] hover:bg-[#9ACA3C] focus:bg-[#9ACA3C]',
                (pathname === ROUTES.VECTORIZER.ROOT ||
                  matchPath(ROUTES.VECTORIZER.VECTORIZED_IMAGE(), pathname)) &&
                  'text-service-white-white bg-[#9ACA3C]',
              )}
              to={ROUTES.VECTORIZER.ROOT}
            >
              <div className="sr-only">Switch to Vectorizer</div>
              <VectorizerIcon className="h-[21px] min-w-[18px]" />
            </PreloadedLink>
          ),
        },
        {
          title: 'CF Background Remover',
          active: true,
          image: (
            <img
              src="/images/tool-background-remover.png"
              width="369"
              height="208"
              className="h-auto w-full bg-gray-800"
              alt="Creative Fabrica Background Remover"
            />
          ),
          icon: (
            <div className="rounded-2 text-service-white-white bg-clover-500 flex h-[48px] min-w-[48px] items-center justify-center">
              <BackgroundRemoverIcon className="h-[24px] min-w-[22px]" />
            </div>
          ),
          description: 'Remove a background from any image',
          id: 'Background Remover',
          topIcon: (
            <PreloadedLink
              aria-label="Switch to Background Remover"
              className={tw(
                'rounded-2 text-gray-400-400 flex cursor-pointer items-center justify-center border-0 p-[5px] focus:outline-none',
                'bg-gray-1000-100 hover:text-service-white-white focus:text-service-white-white hover:bg-clover-500 focus:bg-clover-500 h-[42px] w-[42px]',
                (pathname === ROUTES.BACKGROUND_REMOVER.ROOT ||
                  matchPath(ROUTES.BACKGROUND_REMOVER.IMAGE(), pathname)) &&
                  'text-service-white-white bg-clover-500',
              )}
              to={ROUTES.BACKGROUND_REMOVER.ROOT}
            >
              <div className="sr-only">Switch to Background Remover</div>
              <BackgroundRemoverIcon className="h-[24px] min-w-[22px]" />
            </PreloadedLink>
          ),
        },
        {
          title: 'Creative Fabrica Transfer',
          active: true,
          image: (
            <img
              src="/images/tool-transfer.png"
              width="369"
              height="208"
              className="h-auto w-full bg-gray-800"
              alt="Creative Fabrica Transfer"
            />
          ),
          icon: (
            <div className="rounded-2 text-service-white-white flex h-[48px] min-w-[48px] items-center justify-center bg-[#ED3493]">
              <TransferIcon className="h-[24px] min-w-[22px]" />
            </div>
          ),
          description: 'Send large and small files to others hassle-free',
          id: 'Transfer',
          topIcon: (
            <PreloadedLink
              aria-label="Switch to Transfer"
              className={tw(
                'bg-gray-1000-100 rounded-2 text-gray-400-400 flex cursor-pointer items-center justify-center border-0 p-[5px] focus:outline-none',
                'hover:text-service-white-white focus:text-service-white-white h-[42px] w-[42px] hover:bg-[#ED3493] focus:bg-[#ED3493]',
                pathname.includes(ROUTES.TRANSFER.ROOT) && 'text-service-white-white bg-[#ED3493]',
              )}
              to={ROUTES.TRANSFER.ROOT}
            >
              <TransferIcon className="h-[20px] min-w-[18px]" />
            </PreloadedLink>
          ),
        },
      ].filter(menu => menu.active) as AppSwitchBarItem[],
    [pathname],
  )

  const displayNotShowAgainOption = false

  return (
    <div className="flex flex-row gap-1">
      {menus.map(menu => (
        <Tooltip
          key={menu.id}
          value={
            <>
              <div className="flex w-full flex-grow flex-col justify-between gap-4 px-2 py-1">
                <div className="rounded-2 flex flex-grow overflow-hidden">{menu.image}</div>
                <div className="flex flex-row gap-4">
                  {menu.icon}
                  <div className="flex flex-col gap-2">
                    <p className="flex h-[19px] items-center text-[18px] font-bold leading-4 text-gray-100">
                      {menu.title}
                    </p>
                    <p className="text-gray-400-400 font-medium leading-4">{menu.description}</p>
                  </div>
                </div>
              </div>
              {displayNotShowAgainOption ? (
                <>
                  <Separator />
                  <div className="flex w-full flex-grow flex-row justify-between  p-4">
                    <CheckboxItem className="font-medium" label={"Don't show again"} />
                    <Button
                      className="py-[6px] text-[14px] font-medium leading-3"
                      onClick={() => navigate(`/${menu.id.toLowerCase()}`)}
                    >
                      Switch to {menu.id}
                    </Button>
                  </div>
                </>
              ) : null}
            </>
          }
          sideOffset={9}
          className="bg-gray-1000 rounded-4 z-[100] flex max-h-[400px] w-[400px] flex-col justify-between shadow-md"
          arrowClassName="fill-gray-1000"
        >
          {menu.topIcon}
        </Tooltip>
      ))}
    </div>
  )
}
