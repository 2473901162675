import { signal } from '@preact/signals-react'
import * as Dialog from 'primitives/dialog'
import * as React from 'react'
import { Helmet, HelmetTags } from 'react-helmet-async'
import { lazyComponent } from 'utils/lazy-component'
import { useIsLoggedInQuery } from 'auth/hooks'
import { useUserAuthModal } from 'components/user-auth-dialog/user-auth-dialog-context'
import {
  GoogleOneTapLogin as GoogleOneTapLoginComponent,
  scriptUrl as googleOneTapLoginScriptUrl,
} from 'analytics/google-one-tap-login'
import { useSiteDataQuery } from 'codegen/generated/cf'

/**
 * This component is used to render global dialogs.
 *
 * You are encouraged to _not_ edit this component, and
 * render all Dialogs where you need them, using the headless UI patterns in Dialog.
 * This keeps things co-locate and helps you to manage complexity, as in
 * different cases you need to render the same dialog with different effects.
 * Keeping the dialog rendering logic in the same place as the dialog itself
 * helps you to manage this complexity.
 *
 * Some dialogs, however, are global and we need to give up colocate and introduce
 * complexity. These dialogs need to be rendered in the global scope
 * as when they are rendered in a local scope, they will be unmounted when the local scope is unmounted.
 * This is a problem when you are logging in halfway through checking out,
 * for example, and the payment dialog is unmounted.
 * To avoid this issue we can bring some up to the top of the tree.
 *
 * Again, try to not extend this component if you can avoid it.
 *
 * Currently what should, or is, in here is the:
 * - AuthDialog (coming soon)
 * - PaymentsDialog
 */
export function AppDialogs() {
  return (
    <>
      <PaymentDialogContainer />
      <AuthDialogContainer />
      <SitewidePopup />
      {/* only enable on environments, i.e. not preview environments */}
      {process.env.NEXT_PUBLIC_ENV || process.env.VITE_PUBLIC_ENV ? <GoogleOneTapLogin /> : null}
    </>
  )
}

const NewsletterPopup = lazyComponent(() =>
  import('components/popups/newsletter').then(m => m.NewsletterPopup),
)

export function SitewidePopup() {
  const isLoggedInQuery = useIsLoggedInQuery()
  //The newsletter popup should only be shown to logged in users so we need to wait for the user to be logged in before fetching the site data
  const { data } = useSiteDataQuery(undefined, {
    enabled: isLoggedInQuery.data,
  })

  if (data?.siteData.newsletterPopup) {
    return (
      <React.Suspense fallback={null}>
        <NewsletterPopup.component popup={data?.siteData.newsletterPopup} />
      </React.Suspense>
    )
  }

  return null
}

const GoogleOneTapLogin = () => {
  const isLoggedInQuery = useIsLoggedInQuery()
  const [scriptLoaded, setScriptLoaded] = React.useState(false)

  function onLoad(scriptTags?: HelmetTags) {
    if (!scriptTags || !scriptTags.scriptTags) {
      return
    }

    const scriptTag = scriptTags?.scriptTags[0]

    scriptTag.onload = () => {
      setScriptLoaded(true)
    }
  }

  React.useEffect(() => {
    //If user is already logged in and the script has finished loading cancel the one tap login
    if (isLoggedInQuery.data && scriptLoaded) {
      window.google?.accounts.id.cancel()
    }
  }, [isLoggedInQuery.data, scriptLoaded])

  if (isLoggedInQuery.isFetching || isLoggedInQuery.data) {
    return null
  }

  return (
    <>
      <GoogleOneTapLoginComponent />
      <Helmet onChangeClientState={(_newState, addedTags) => onLoad(addedTags)}>
        <script
          id="global-one-tap-login-script"
          type="text/javascript"
          src={googleOneTapLoginScriptUrl}
        />
      </Helmet>
    </>
  )
}

export function useSetPaymentsDialogVisibility() {
  const isLoggedInQuery = useIsLoggedInQuery()
  const { openModal } = useUserAuthModal()

  function openPaymentsModal() {
    if (isLoggedInQuery?.data) {
      setPaymentsDialogVisibility(true)
    } else {
      openModal('register', { successCallback: () => () => setPaymentsDialogVisibility(true) })
    }
  }

  return openPaymentsModal
}

export const paymentDialog = signal(false)
export const setPaymentsDialogVisibility = (next: boolean) => (paymentDialog.value = next)

const PaymentsDialog = lazyComponent(() =>
  import('@studio/components/payments-dialog').then(m => m.PaymentsDialog),
)

const AuthDialog = lazyComponent(() =>
  import('components/user-auth-dialog/index').then(m => m.UserAuthDialog),
)

const AuthDialogContainer = () => {
  const isLoggedInQuery = useIsLoggedInQuery()

  if (isLoggedInQuery?.data) {
    return null
  }

  return (
    <React.Suspense fallback={null}>
      <AuthDialog.component bodyClassName="text-aubergine-500 font-poppins" />
    </React.Suspense>
  )
}

export const OPEN_PAYMENT_DIALOG_EVENT_NAME = 'cf_open_payment_dialog'

function PaymentDialogContainer() {
  React.useEffect(() => void PaymentsDialog.preload(), [])
  const openPaymentsModal = useSetPaymentsDialogVisibility()

  React.useEffect(() => {
    document.addEventListener(OPEN_PAYMENT_DIALOG_EVENT_NAME, openPaymentsModal)

    return () => {
      document.removeEventListener(OPEN_PAYMENT_DIALOG_EVENT_NAME, openPaymentsModal)
    }
  }, [openPaymentsModal])

  return (
    <Dialog.Root
      open={paymentDialog.value}
      onOpenChange={setPaymentsDialogVisibility}
      defaultOpen={false}
    >
      <Dialog.Portal forceMount>
        <React.Suspense fallback={null}>
          <Dialog.Body dismissable={false} zIndex={70}>
            <PaymentsDialog.component />
          </Dialog.Body>
        </React.Suspense>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
