// add routes here so they update everywhere when changed.
export const ROUTES = {
  STUDIO: {
    ROOT: '/',
    HOME: '/',
    DEFAULT_LOGGED_OUT: '/studio/create',
    UNAUTHORIZED_TO_VIEW_PROJECT: '/?unauthorized=true',
    NEW_PROJECT: '/studio/create',
    TOOLBARS: {
      UPLOADS: '/studio/create?navigation-bar=uploads',
    },
    NEW_PROJECT_WITH_TEMPLATE: (templateCategory: string, templateId?: string) =>
      `/studio/create/?template_category=${templateCategory}&content_template_id=${templateId}`,
    SHARED_PROJECTS: {
      ROOT: '/studio/shared-projects',
      TEAMS: '/studio/shared-projects/teams',
      USERS: '/studio/shared-projects/users',
    },
    // can be used for matchPath
    PROJECT: (id?: string) => (id ? `/studio/create/${id}` : '/studio/create/:id'),
    PROJECTS: '/studio/projects',
    FOLDERS: {
      FOLDER: (folderId?: string) =>
        folderId ? `/studio/folders/${folderId}` : '/studio/folders/:folderId',
    },
    TEAMS: {
      TEAM: {
        ROOT: (teamId?: string) => (teamId ? `/studio/teams/${teamId}` : '/studio/teams/:teamId'),
        PROJECTS: (teamId?: string) =>
          teamId ? `/studio/teams/${teamId}/projects` : '/studio/teams/:teamId/projects',
        FOLDERS: {
          FOLDER: (teamId?: string, folderId?: string) =>
            teamId && folderId
              ? `/studio/teams/${teamId}/folders/${folderId}`
              : '/studio/teams/:teamId/folders/:folderId',
        },
        USERS: (teamId?: string) =>
          teamId ? `/studio/teams/${teamId}/users` : '/studio/teams/:teamId/users',
        TEMPLATES: (teamId?: string) =>
          teamId ? `/studio/teams/${teamId}/templates` : '/studio/teams/:teamId/templates',
        BRAND_HUB: (teamId?: string) =>
          teamId ? `/studio/teams/${teamId}/brand-hub` : '/studio/teams/:teamId/brand-hub',
        TRASH: (teamId?: string) =>
          teamId ? `/studio/teams/${teamId}/trash` : '/studio/teams/:teamId/trash',
      },
    },
    TRASH: '/studio/trash',
  },
  DESIGN_REVIEWER: {
    ROOT: '/design-reviewer',
    DESIGN: (id?: string) => (id ? `/design-reviewer/${id}` : '/design-reviewer/:id'),
  },
  VECTORIZER: {
    ROOT: '/vectorizer',
    VECTORIZED_IMAGE: (id?: string) => (id ? `/vectorizer/${id}` : '/vectorizer/:id'),
  },
  BACKGROUND_REMOVER: {
    ROOT: '/background-remover',
    IMAGE: (id?: string) => (id ? `/background-remover/${id}` : '/background-remover/:id'),
  },
  FLOW: {
    ROOT: '/flow',
    FLOW: (id?: string) => (id ? `/flow/${id}` : '/flow/:id'),
    ALL: '/flow/all',
  },
  BOARDS: {
    ROOT: '/boards',
  },
  HEALTH_CHECK: {
    ROOT: '/healthcheck',
  },
  TRANSFER: {
    ROOT: '/transfer',
  },
  TEMPLATES: {
    ROOT: '/templates',
    ITEM: '/templates/item',
  },
}
