import * as Sentry from '@sentry/react'
import merge from 'lodash.merge'

import { clientIgnoreErrors, denyUrls } from './constants'
import { beforeSend } from './options'

export const SentryErrorBoundary = Sentry.ErrorBoundary
export const withSentryErrorBoundary = Sentry.withErrorBoundary

export function initSentry({
  dsn,
  sentryOptions,
}: {
  dsn: string
  sentryOptions?: Sentry.BrowserOptions
}) {
  //this package is getting imported in multiple other packages/apps so we need to make sure we only initialize it once
  if (typeof window !== 'undefined' && window.CF_PAYMENTS_SENTRY_INITIALIZED) {
    return
  }

  // helps debug sentry with the sentry_debug query param
  const debug = new URLSearchParams(window.location.search).get('sentry_debug') !== null

  const base: Sentry.BrowserOptions = {
    ignoreErrors: clientIgnoreErrors,
    debug,
    denyUrls,
    enabled: false,
    environment: import.meta.env.VITE_PUBLIC_ENV === 'production' ? 'production' : 'development',
    dsn,
    // on production we set it to 1 in 200 (0.005, (0.5%)) of all sessions
    // for the rest we set it to 1 in 4 (0.25, (25%)) of all sessions
    tracesSampleRate: import.meta.env.VITE_PUBLIC_ENV === 'production' ? 1 / 200 : 1 / 4,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/creativefabrica\.com/],

    // Capture Replay for 0% of all sessions,
    replaysSessionSampleRate: 0,
    // plus for 100% of sessions with an error
    replaysOnErrorSampleRate: 1.0,
    beforeSend,
  }

  const options = merge(base, sentryOptions)

  Sentry.init(options)

  if (typeof window !== 'undefined') {
    window.CF_PAYMENTS_SENTRY_INITIALIZED = true
  }
}
