import { queryClient } from '@studio/query-client'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { UserAuthModalProvider } from 'components/user-auth-dialog/user-auth-dialog-context'
import * as React from 'react'
import ReactDOM from 'react-dom/client'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { RouterProvider } from 'react-router-dom'
import { lazyComponent } from 'utils/lazy-component'

import { AuthProvider } from './auth/auth-provider'
import { Analytics } from './components/analytics/analytics'
import { AppDialogs } from './components/app-dialogs'
import { DarkModeProvider } from './context/dark-mode/dark-mode.context'
import './index.css'
import { initPolyfills } from './polyfills'
import { router } from './router'
import './sentry'
import { checkAndHandleAuth } from './utils/auth'
import { initDarkMode } from './utils/dark-mode'

import 'styles/main.css'

const ErrorPage = lazyComponent(() => import('./routes/error-page').then(m => m.ErrorPage))

await Promise.allSettled([initDarkMode(), initPolyfills(), checkAndHandleAuth()])

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet titleTemplate="%s - CF Studio" />
      <ErrorBoundary FallbackComponent={ErrorPage.component}>
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV === 'development' ? (
            <ReactQueryDevtools initialIsOpen={false} />
          ) : null}
          <AuthProvider>
            <DarkModeProvider>
              <UserAuthModalProvider>
                {!import.meta.env.DEV ? <Analytics /> : null}
                <AppDialogs />
                <RouterProvider router={router} />
              </UserAuthModalProvider>
            </DarkModeProvider>
          </AuthProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </HelmetProvider>
  </React.StrictMode>,
)
