import { motion } from 'framer-motion'
import { SpinnerIcon } from 'primitives/icons'
import { StudioIconLogo } from 'primitives/icons/local'
import { TopAppBar } from '@studio/components/top-app-bar'

export function PageLoader() {
  return (
    <section className="flex h-dvh w-dvw flex-col">
      <TopAppBar />
      <motion.div
        className="flex grow flex-col items-center justify-center gap-6"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.5 }}
      >
        <StudioIconLogo className="h-auto w-[75px]" />
        <SpinnerIcon className="w-4 animate-spin" />
      </motion.div>
    </section>
  )
}
